import firebase from 'firebase';
import 'firebase/firestore';
import { createStore, combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import { devToolsEnhancer } from 'redux-devtools-extension';

// Reducers
import notyfyReducer from './reducers/notifyReducer';
import settingsReducer from './reducers/settingsReducer';

const firebaseConfig = {
  apiKey: 'AIzaSyAbXcD7Ws9L6D3dH-BVmyx4A_wkbDY5rGo',
  authDomain: 'waas-0003.firebaseapp.com',
  databaseURL: 'https://waas-0003.firebaseio.com',
  projectId: 'waas-0003',
  storageBucket: 'waas-0003.appspot.com',
  messagingSenderId: '450043551473',
  appId: '1:450043551473:web:b7f747a020eb21dcf45350',
  measurementId: 'G-K7VXRXECSS',
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize firestore
const firestore = firebase.firestore();
// const settings = { timestampsInSnapshots: true };
// firestore.settings(settings);

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notify: notyfyReducer,
  settings: settingsReducer
});

// Check for settings in localStorage
if (localStorage.getItem('settings') == null) {
  // Default settings
  const defaultSettings = {
    // disableBalanceOnAdd: true,
    // disableBalanceOnEdit: false,
    allowRegistration: false
  };

  // Set to localStorage
  localStorage.setItem('settings', JSON.stringify(defaultSettings));
}

// Create initial state
const initialState = { settings: JSON.parse(localStorage.getItem('settings') as string) };

// Create store
export const store = createStore(
  rootReducer,
  initialState,
  devToolsEnhancer({})
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

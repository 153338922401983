import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, rrfProps } from './store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import { UserIsAuthenticated, UserIsNotAuthenticated } from './helpers/auth';
import AppNavbar from './components/layout/AppNavbar';
import Dashboard from './components/layout/Dashboard';
import AddUser from './components/users/AddUser';
import UserDetails from './components/users/UserDetails';
import EditUser from './components/users/EditUser';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Settings from './components/settings/Settings';


import './App.css';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router>
            <div className="App">
              <AppNavbar />
              <div className="container">
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={UserIsNotAuthenticated(Login)}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    component={UserIsAuthenticated(Dashboard)}
                  />
                  <Route
                    exact
                    path="/user/add"
                    component={UserIsAuthenticated(AddUser)}
                  />
                  <Route
                    exact
                    path="/user/edit/:id"
                    component={UserIsAuthenticated(EditUser)}
                  />
                  <Route
                    exact
                    path="/user/:id"
                    component={UserIsAuthenticated(UserDetails)}
                  />
                  <Route
                    exact
                    path="/login"
                    component={UserIsNotAuthenticated(Login)}
                  />
                  <Route
                    exact
                    path="/register"
                    component={UserIsNotAuthenticated(Register)}
                  />
                  <Route
                    exact
                    path="/settings"
                    component={UserIsAuthenticated(Settings)}
                  />
                </Switch>
              </div>
            </div>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
}

export default App;
